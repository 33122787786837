import Image from 'next/image';

import { IconAt, IconDeviceMobile } from '@tabler/icons-react';

const DEFAULT_IMAGE_SIZE = 22;

const AuthProviderLogo: React.FC<{
  providerId: string;
  width?: number;
  height?: number;
}> = ({ providerId, width, height }) => {
  const image = getOAuthProviderLogos()[providerId];

  if (typeof image === 'string') {
    return (
      <Image
        decoding={'async'}
        loading={'lazy'}
        src={image}
        alt={`${providerId} logo`}
        width={width ?? DEFAULT_IMAGE_SIZE}
        height={height ?? DEFAULT_IMAGE_SIZE}
      />
    );
  }

  return <>{image}</>;
};

function getOAuthProviderLogos(): Record<string, string | React.ReactNode> {
  return {
    password: <IconAt className={'h-[22px] w-[22px]'} />,
    phone: <IconDeviceMobile className={'h-[22px] w-[22px]'} />,
    google: '/assets/images/google.webp',
    microsoft: '/assets/images/microsoft.webp',
  };
}

export default AuthProviderLogo;
